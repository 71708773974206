import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

const useEmail = () => {
  const [isButtonLoading, setButtonLoading] = useState(false)
  const { changeLeadData, leadData } = useLead()

  useEffect(() => {
    changeLeadData({
      previousStep: '/proposta/nome'
    })
    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-email',
      customLayer: { variant: leadData?.vwoVariant }
    })
  }, [])

  const onSubmit = async ({ email }) => {
    setButtonLoading(true)

    changeLeadData({
      email,
      previousStep: '/proposta/email'
    })

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-email',
      detail: 'click-step-basic'
    })

    goTo('/proposta/telefone')

    setButtonLoading(false)
  }

  return {
    onSubmit,
    isButtonLoading
  }
}

export default useEmail
